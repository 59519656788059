.footer-container {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 15px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 50px;
    margin: 00px auto 0 auto;
    margin: auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100px;
    margin-left: 18px;
    margin-top: 10px;
  }
  
  .social-logo {
    color: #fff;
    /* justify-self: start; */
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
    font-size: 11px;
    text-align: center;
    margin-top: 12px;
  }

  .overall-footer{
    align-items: center;
    margin: auto;
    text-align: center;
  }

  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }