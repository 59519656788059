.cards {
    background: #ffffff;
  }
  
  h1 {
    text-align: center;
    font-size: 3rem;
    margin-top: -5px;
    font-weight: lighter;

  }

  .cards__item__img {
    /* position: absolute; */
    margin-top: 55px;
    margin-left: 24px;
    right: 0;
    bottom: 0;
    left: 20%;
    width: 256px;
    height: 400px;
    object-fit: cover;
    transition: all 0.2s linear;
    
  }

  .cards__item__inner{
    width: 600px;
    display: flex;
    align-items: center;
    /* margin: auto; */
}

  .cards__item__text {
    color: #252e48;
    font-size: 12px;
    line-height: 17px;
    margin-left: 17px;
    margin-right: 27px;
    text-align: center;
    margin-top: 8px;
    width: 256px;
  }

  .rows {
    display: flex;
    margin: auto;
    width: max-content;
  }

  .single-rows {
    display: flex;
    margin: auto;
    width: max-content;
  }

  .not-rows {
    display: flex;
    margin: auto;
    width: 1200px;
}

  .book__title {
    color: #252e48;
    font-size: 27px;
    line-height: 10px;
    margin-left: 17px;
    margin-right: 27px;
    text-align: center;
    margin-top: 60px;
    width: 256px;
  }

  .link-wrap {
    color: #252e48;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    align-items: center;
    margin-top: 15px;
    width: 256px;
    margin-left: 17px;
    margin-right: 27px;
  }

  .Wattpad {
    /* position: absolute; */
    margin-bottom: 1px;
    right: 0;
    bottom: 0;
    left: 20%;
    height: 23px;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .Quotev {
    height: 27px;
    transition: all 0.2s linear;
    margin-left: 2.3px;
  }

  .AO3 {
    /* position: absolute; */
    margin-top: 14px;
    right: 0;
    bottom: 0;
    height: 25px;
    object-fit: cover;
    transition: all 0.2s linear;
    /* margin-left: 2.8px; */
    /* margin-right: 2px; */
  }

  .Fanfiction {
    /* position: absolute; */
    margin-top: 14px;
    right: 0;
    bottom: 0;
    left: 20%;
    height: 27px;
    object-fit: cover;
    transition: all 0.2s linear;
    margin-left: 5px;
    margin-right: 2px;
  }
  
  @media only screen and (max-width: 750px) {
    .content__blog__container {
      width: 84%;
    }
    .rows {
        display: inline;
        margin: auto;
      }
  
    .content__blog__container {
      width: 84%;
    }
    .rows {
        display: inline;
        margin: auto;
        align-items: center;
      }
      .cards__item__inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin: auto; */
    }

    .cards__item__img {
      /* position: absolute; */
      margin-top: 55px;
      margin-left: 0;
      right: 0;
      bottom: 0;
      width: 256px;
      height: 400px;
      object-fit: cover;
      transition: all 0.2s linear;
      align-items: center;
      
    }
    .not-rows {
      display: inline;
      width: 100%;
  }
  .link-wrap {
    color: #252e48;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    align-items: center;
    margin-top: 15px;
    width: 256px;
    margin-left: 10px;
  }

  .cards__item__text {
    color: #252e48;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-top: 8px;
    width: 70%;
    align-items: center;
  }

  .cards__item__text {
    color: #252e48;
    font-size: 12px;
    line-height: 17px;
    margin-left: 17px;
    margin-right: 27px;
    text-align: center;
    margin-top: 8px;
    width: 256px;
  }

  .text {
    color: #252e48;
    line-height: 17px;
    text-align: center;
    margin-left: 15px;
  }
  
}
