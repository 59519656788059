.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  /* max-width: 5000px; */
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  /* margin-left: 25px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  height: 40px;
  /* letter-spacing: 1px; */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem 1rem;
  height:100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}


/* Social Icons */
.social-icon-link-nav {
  color: #fff;
  font-size: 15px;
}

.social-media-wrap-nav {
  display: inline;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 100px;
  margin: 60px auto 0 auto;
  margin: auto;
}

.social-icons-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  margin-left: 15px;
  margin-bottom: 32px;
  margin-top: 32px;
}



@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease-out;
    height: 250px;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    top: 80px;
    opacity: 0.85;
    transition: all 0.5s ease;
    z-index: 1;
    height: 250px;
    /* align-items: center; */
    width: 100%;
  }

  .nav-links {
    align-items: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;

    /* width: 1000px;
    left: -500px; */
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(8%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.7rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    font-size: 1.1rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
    width: 100%;
  }

  /* Social Icons */
  .social-icon-link-nav {
      color: #fff;
      font-size: 15px;
      /* margin-bottom: 60px; */
      width: 100%;

  }

  .social-media-wrap-nav {
      display: block;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 60px;
      /* margin-bottom: 60px; */
  }
  
  .social-icons-nav {
      display: absolute;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      width: 20%;
      margin-left: 40%;
      margin-top: 10px;
  }
}

@media screen and (max-width: 700px) {
.navbar-logo {
  color: #fff;
  justify-self: start;
  /* margin-left: 25px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  height: 40px;
  /* letter-spacing: 1px; */
}
}