image{
    object-fit: cover;
    width: 100vh;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.hero-container {
    background: url('./images/ocean-image.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    left: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
    object-fit: contain;
}

.intro{
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display Regular 400 Italic', serif;
    font-style: italic;
    font-weight: lighter;
    font-size: 0.9rem;
    left: 100px;
    text-shadow: 3px 1px 20px black;
}



.contact-info{
    text-align: center;
    color: #fff;
    font-family: 'Playfair Regular 400 Italic', serif;
    font-weight: lighter;
    font-size: 0.95rem;
    left: 100px;
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 35rem;
}

.email{
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display Regular 400 Italic', serif;
    font-weight: lighter;
    font-size: 0.8rem;
    left: 100px;
    margin-top: 8px;
}

h1{
    color: white;
}

.contact-paragraph{
    width: 18.5rem;
    align-items: center;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

form{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

input {
    padding: 3%;
    width: 15rem;
}

textarea {
    padding: 3%;
    width: 15rem;
}

button{
    padding: 3%;
    width: 10rem;
}

.socials-icon{
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100px;
    margin-top: 1rem;
    font-size: 20px;
}

.socials{
    display: flex;
    justify-content: center;
    font-size: 20px;

}

/* @media screen and (max-width: 760px) {
    .intro{
        text-align: center;
        color: #fff;
        font-family: 'Playfair Display Regular 400 Italic', serif;
        font-style: italic;
        font-weight: lighter;
        font-size: 0.9rem;
        left: 100px;
        margin-top: -20px;
    }

    .contact-info{
        text-align: center;
        color: #fff;
        font-family: 'Playfair Display Regular 400 Italic', serif;
        font-weight: lighter;
        text-shadow: inset 0 0 0 1000px rgba(0,0,0,0);
        font-size: 0.9rem;
        margin-top: -40px;
    }
    
    .contact-info p{
        margin-bottom: 17px;
        color: #fff;
        font-weight: lighter;
    }
    .hero-container {
        background: url('./images/ocean-image.jpg') center center/cover no-repeat;
        width: 100%;
        left: 000px;
        display: flex;
        flex-direction: column;
        box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
    }
} */